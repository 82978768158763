import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"],
];

export const HelpTiList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List {...props} title="Tijaretchi帮助" className={classes.ugTitle}>
      {isSmall ? (
        <SimpleList
          primaryText={() => "标题"}
          secondaryText={(record) => `${record.title.zh} ${record.title.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField label="标题（中文）" source="title.zh" />
          <TextField label="标题（维文）" source="title.ug" />
          <DeleteButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const optionText = (record) => `${record.name.zh} ${record.name.ug}`;

export const HelpTiEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title="编辑Tijaretchi帮助" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput source="title.zh" label="汉语标题" validate={required()} />
        <TextInput source="title.ug" label="维文标题" validate={required()} />

        <ReferenceInput
          validate={required()}
          reference="help/categories"
          source="category_id"
          label="目录帮助"
        >
          <SelectInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <RichTextInput
          validate={required()}
          source="content.zh"
          label="帮助说明（中文）"
          toolbar={toolbarOptions}
        />
        <RichTextInput
          validate={required()}
          source="content.ug"
          label="帮助说明（维文）"
          toolbar={toolbarOptions}
        />
      </SimpleForm>
    </Edit>
  );
};

export const HelpTiCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} title="新建Tijaretchi帮助" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="title.zh" label="汉语标题" validate={required()} />
        <TextInput source="title.ug" label="维文标题" validate={required()} />

        <ReferenceInput
          validate={required()}
          reference="help/categories"
          source="category_id"
          label="目录帮助"
        >
          <SelectInput optionText={optionText} optionValue="id" />
        </ReferenceInput>
        <RichTextInput
          validate={required()}
          source="content.zh"
          label="帮助说明（中文）"
          toolbar={toolbarOptions}
        />
        <RichTextInput
          validate={required()}
          source="content.ug"
          label="帮助说明（维文）"
          toolbar={toolbarOptions}
        />
      </SimpleForm>
    </Create>
  );
};
