import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  DeleteButton,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

const AccountsFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label="姓名" source="name" alwaysOn />
      <TextInput label="电话" source="mobile" alwaysOn />
    </Filter>
  );
};

export const RecommendersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      title="推荐人管理"
      filters={<AccountsFilter />}
      className={classes.ugTitle}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("accounts.account")}
          secondaryText={(record) => `${record.name} ${record.mobile}`}
        />
      ) : (
        <Datagrid>
          <TextField label="电话" source="mobile" />
          <TextField label={translate("accounts.contact")} source="name" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ mobile: searchText });

const mobileMatch = (filter, choice) =>
  choice &&
  typeof choice.mobile === "string" &&
  choice.mobile.includes(`${filter}`);

const optionText = (record) => `${record.name} ${record.mobile}`;

export const RecommendersEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title="编辑推荐" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput source="mobile" label="电话" validate={required()} />
        <TextInput source="name" label="姓名" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const RecommendersCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} title="新建推荐" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceInput
          validate={required()}
          reference="users"
          source="user_id"
          label="用户"
          filterToQuery={filterToUser}
        >
          <AutocompleteInput
            optionText={optionText}
            optionValue="id"
            matchSuggestion={mobileMatch}
          />
        </ReferenceInput>
        <TextInput source="mobile" label="电话" validate={required()} />
        <TextInput source="name" label="姓名" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
