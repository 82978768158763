import {
  ListButton,
  SaveButton,
  Toolbar,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { makeStyles, Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

export const TopBarActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="返回列表" />
  </TopToolbar>
);

export const CreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="保存并返回" redirect="list" submitOnEnter={true} />
    <SaveButton
      label="保存并继续添加"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);

const AvatarField = ({ record, size = "25", className }) =>
  record ? (
    <Avatar
      src={`${record.avatar}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

export const FullNameField = ({ record, size }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {record.name ?? record.nickname}
    </div>
  ) : null;
};

export const UsersGender = ({ source }) => {
  const record = useRecordContext();
  let gender = "";
  if (record) {
    switch (record.gender) {
      case "0":
        gender = "未指定";
        break;
      case "1":
        gender = "男";
        break;
      case "2":
        gender = "女";
        break;
      default:
        break;
    }
  }
  return gender;
};

export const imageInputFormat = (file) => {
  console.log(file);
  if (file && file.rawFile instanceof File) {
    return file;
  }
  if (file && Array.isArray(file)) {
    const arr = [];
    file.forEach((i) => {
      if (i && i.rawFile instanceof File) {
        arr.push({ src: i });
      } else if (i.url) {
        arr.push({ src: i.url });
      } else {
        arr.push(i);
      }
    });
    return arr;
  }
  return { src: file };
};
