import {
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  DateField,
  PasswordInput,
  DateInput,
  Filter,
  BulkExportButton,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { TopBarActions } from "../components";
import { Fragment } from "react";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

const AccountsFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        label={translate("official.search")}
        source="keyword"
        alwaysOn
      />
      <DateInput
        label={translate("official.start_at")}
        source="start_at"
        alwaysOn
      />
      <DateInput
        label={translate("official.end_at")}
        source="end_at"
        alwaysOn
      />
    </Filter>
  );
};

const PostBulkActionButtons = (props) => {
  return (
    <Fragment>
      <BulkExportButton {...props} />
    </Fragment>
  );
};

export const DelAccountsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      title={translate("official.accountdel")}
      filters={<AccountsFilter />}
      className={classes.ugTitle}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("accounts.account")}
          secondaryText={(record) =>
            `${record.account_name} ${record.contact_name}`
          }
        />
      ) : (
        <Datagrid>
          <TextField
            label={translate("accounts.account")}
            source="account_name"
          />
          <TextField
            label={translate("accounts.contact")}
            source="contact_name"
          />
          <TextField source="mobile" label={translate("accounts.mobile")} />
          <DateField
            source="expired_at"
            locales="zh-CN"
            label={translate("official.expired_at")}
            showTime
          />
          <DateField
            source="paid_at"
            locales="zh-CN"
            label={translate("accounts.paytime")}
            showTime
          />
          <DateField
            source="trial_started_at"
            locales="zh-CN"
            label={translate("accounts.start")}
            showTime
          />
          <DateField
            source="trial_ended_at"
            locales="zh-CN"
            label={translate("accounts.end")}
            showTime
          />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export const DelAccountsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("accounts.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="mobile"
          label={translate("accounts.member")}
          validate={required()}
        />
        <TextInput
          source="account_name"
          label={translate("accounts.account")}
          validate={required()}
        />
        <PasswordInput
          source="password"
          label={translate("accounts.password")}
        />
        <TextInput
          source="contact_name"
          label={translate("accounts.contact")}
        />
        <TextInput
          source="contact_mobile"
          label={translate("accounts.mobile")}
        />
        <DateInput
          source="expired_at"
          label={translate("official.expired_at")}
        />
        <DateInput source="paid_at" label={translate("accounts.paytime")} />
        <DateInput
          source="trial_started_at"
          label={translate("accounts.start")}
        />
        <DateInput source="trial_ended_at" label={translate("accounts.end")} />
      </SimpleForm>
    </Edit>
  );
};
