import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  AutocompleteInput,
  ReferenceInput,
  DeleteButton,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

export const WechatList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List {...props} title="微信支付商户号管理" className={classes.ugTitle}>
      {isSmall ? (
        <SimpleList
          primaryText={() => "店铺名称"}
          secondaryText={(record) => `${record.account.data.account_name}`}
        />
      ) : (
        <Datagrid>
          <TextField label="店铺名称" source="account.data.account_name" />
          <TextField
            label={translate("accounts.mobile")}
            source="account.data.contact_mobile"
          />
          <TextField
            label={translate("accounts.contact")}
            source="account.data.contact_name"
          />
          <TextField label="商铺ID" source="wechat_merchant_id" />
          <TextField label="商铺KEY" source="wechat_merchant_key" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const filterToUser = (searchText) => ({ mobile: searchText });

const mobileMatch = (filter, choice) =>
  choice &&
  typeof choice.id === "string" &&
  choice.mobile.includes(`${filter}`);

const optionText = (record) =>
  `${record.contact_mobile} ${record.account_name} `;

export const WechatEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title="编辑支付" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput
          source="wechat_merchant_id"
          label="商户ID"
          validate={required()}
        />
        <TextInput
          source="wechat_merchant_key"
          label="商户的key"
          validate={required()}
        />
        <TextInput source="v3_key" label="v3的key" />
        <TextInput source="api_cert" label="api的cert" />
        <TextInput source="api_key" label="api的key" />
      </SimpleForm>
    </Edit>
  );
};

export const WechatCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} title="新建支付" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceInput
          validate={required()}
          reference="admin/accounts"
          source="account_id"
          label={translate("accounts.account")}
          filterToQuery={filterToUser}
        >
          <AutocompleteInput
            optionText={optionText}
            optionValue="id"
            matchSuggestion={mobileMatch}
          />
        </ReferenceInput>
        <TextInput
          source="wechat_merchant_id"
          label="商户Id"
          validate={required()}
        />
        <TextInput
          source="wechat_merchant_key"
          label="商户的key"
          validate={required()}
        />
        <TextInput source="v3_key" label="v3的key" />
        <TextInput source="api_cert" label="api的cert" />
        <TextInput source="api_key" label="api的key" />
      </SimpleForm>
    </Create>
  );
};
