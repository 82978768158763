import {
  Datagrid,
  DateField,
  List,
  SimpleList,
  TextField,
  useLocale,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    width: "2vh",
    height: "2vh",
  },
}));

export const OrderssList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const local = useLocale();
  const translate = useTranslate();

  const PostPanel = ({ record }) => (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "0 3vw 0 3vw" }} />
      <div>{translate("accounts.name")}：</div>
      <div
        dangerouslySetInnerHTML={{ __html: record.account?.data.account_name }}
      />
      <div style={{ margin: "0 3vw 0 3vw" }} />
      <div>{translate("accounts.mobile")}：</div>
      <div
        dangerouslySetInnerHTML={{
          __html: record.account?.data.contact_mobile,
        }}
      />
      <div style={{ margin: "0 3vw 0 3vw" }} />
      <div>{translate("accounts.contact")}：</div>
      <div
        dangerouslySetInnerHTML={{ __html: record.account?.data.contact_name }}
      />
      <div style={{ margin: "0 3vw 0 3vw" }} />
      <div>{translate("accounts.paytime")}：</div>
      <div dangerouslySetInnerHTML={{ __html: record.account?.data.paid_at }} />
    </div>
  );

  return (
    <List
      {...props}
      title={translate("orders.title")}
      className={classes.ugTitle}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("orders.num")}
          secondaryText={(record) => `${record.order_no} `}
        />
      ) : (
        <Datagrid expand={<PostPanel />}>
          <TextField source="order_no" label={translate("orders.num")} />
          <DateField
            source="created_at"
            label={translate("orders.created_at")}
            showTime
          />
          <TextField
            source={
              local === "zh"
                ? "account_promotion.description.zh"
                : "account_promotion.description.ug"
            }
            label={translate("promotion.name")}
          />
        </Datagrid>
      )}
    </List>
  );
};
