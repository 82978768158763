import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { DashboardMenuItem, MenuItemLink, useTranslate } from "react-admin";
import UserIcon from "@material-ui/icons/Group";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SubMenu from "./SubMenu";

const Menu = ({ dense = false }) => {
  const translate = useTranslate();
  const [state, setState] = useState({
    menuAccount: true,
    menuHelp: true,
  });
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div className={classes.root}>
      <DashboardMenuItem />
      <SubMenu
        handleToggle={() => handleToggle("menuAccount")}
        isOpen={state.menuAccount}
        name={translate("menu.accounts")}
        icon={<UserIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/accounts"
          primaryText={translate("official.account")}
          leftIcon={<UserIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/accountsDel"
          primaryText={translate("official.accountdel")}
          leftIcon={<UserIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/tryaccounts"
          primaryText={translate("official.tryaccount")}
          leftIcon={<PeopleOutlineIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/tryaccountsDel"
          primaryText={translate("official.tryaccountdel")}
          leftIcon={<PeopleOutlineIcon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/account/promotions"
        primaryText="产品管理"
        leftIcon={<AddToQueueIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/account/orders"
        primaryText="订单管理"
        leftIcon={<ListAltIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/recommenders"
        primaryText="推荐人管理"
        leftIcon={<GroupAddIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/wechat/merchant/payments"
        primaryText="微信支付商户号管理"
        leftIcon={<MonetizationOnIcon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuHelp")}
        isOpen={state.menuHelp}
        name="帮助管理"
        icon={<LiveHelpIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/help/categories"
          primaryText="帮助目录"
          leftIcon={<ContactSupportIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/helps/chay"
          primaryText="茶会帮助"
          leftIcon={<LiveHelpIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/helps/tijaretchi"
          primaryText="Tijaretchi帮助"
          leftIcon={<LiveHelpIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/helps/re"
          primaryText="销售宝帮助"
          leftIcon={<LiveHelpIcon />}
          dense={dense}
        />
      </SubMenu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export default Menu;
