import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  DeleteButton,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

export const PromotionsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("promotion.title")}
      className={classes.ugTitle}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("promotion.name")}
          secondaryText={(record) =>
            `${record.description.zh} ${record.description.ug}`
          }
        />
      ) : (
        <Datagrid>
          <TextField source="month" label={translate("promotion.month")} />
          <TextField
            source="gift_month"
            label={translate("promotion.gift_month")}
          />
          <TextField source="price" label={translate("promotion.price")} />
          <TextField
            source="discounted_price"
            label={translate("promotion.discounted_price")}
          />
          <TextField
            source="description.zh"
            label={translate("promotion.zhname")}
          />
          <TextField
            source="description.ug"
            label={translate("promotion.ugname")}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export const PromotionsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("promotion.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source="month"
          label={translate("promotion.month")}
          validate={required()}
        />
        <TextInput
          source="gift_month"
          label={translate("promotion.gift_month")}
        />
        <TextInput
          source="price"
          label={translate("promotion.price")}
          validate={required()}
        />
        <TextInput
          source="discounted_price"
          label={translate("promotion.discounted_price")}
        />
        <TextInput
          source="description.zh"
          label={translate("promotion.zhname")}
          validate={required()}
        />
        <TextInput
          source="description.ug"
          label={translate("promotion.ugname")}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export const PromotionsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      title={translate("promotion.create")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="month"
          label={translate("promotion.month")}
          validate={required()}
        />
        <TextInput
          source="gift_month"
          label={translate("promotion.gift_month")}
        />
        <TextInput
          source="price"
          label={translate("promotion.price")}
          validate={required()}
        />
        <TextInput
          source="discounted_price"
          label={translate("promotion.discounted_price")}
        />
        <TextInput
          source="zh"
          label={translate("promotion.zhname")}
          validate={required()}
        />
        <TextInput
          source="ug"
          label={translate("promotion.ugname")}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
