import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { useTranslate } from "react-admin";

const Dashboard = () => {
  const translate = useTranslate();

  return (
    <Card>
      <CardHeader title="Welcome to the super admin" />
      <CardContent>超级用户 界面</CardContent>
      <CardContent>{translate("official.lang")}</CardContent>
    </Card>
  );
};

export default Dashboard;
