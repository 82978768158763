import chineseMessages from "ra-language-chinese";
import { mergeTranslations } from "react-admin";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...mergeTranslations(chineseMessages),
  menu: {
    accounts: "账户管理",
    subemployee: "员工管理",
    roles: "角色管理",
    employees: "员工",
    subproduct: "商品管理",
    categories: "商品类目",
    units: "商品单元",
    products: "商品",
    subinventory: "库存管理",
    inventory: "库存查询",
    inventorycount: "库存统计",
    inventorycheck: "盘点历史",
    subpurchase: "采购管理",
    suppliers: "供应商",
    purchase: "采购单",
    subpermission: "会员管理",
    members: "会员",
    levels: "会员等级",
    types: "支付方式",
  },
  common: {
    switch: "切换语言",
    account: "切换账户",
    zhname: "中文名称",
    ugname: "维文名称",
    choose: "账户选择",
    chooseaccount: "请选择账户",
    logintitle: "进销存后台管理系统",
  },
  accounts: {
    mobile: "联系电话",
    member: "登陆账号（电话）",
    password: "登录密码",
    search: "手机号搜索",
    account: "账户",
    contact: "联系人",
    name: "账户名称",
    paytime: "支付时间",
    start: "试用开始",
    end: "试用结束",
    edit: "编辑账户",
    create: "新建账户",
  },
  official: {
    tryaccount: "试用账号",
    tryaccountdel: "到期试用账号",
    account: "正式账户",
    accountdel: "到期正式账户",
    search: "账户搜索",
    start_at: "开始日期",
    end_at: "结束日期",
    expired_at: "到期时间",
    lang: "zh",
  },
  promotion: {
    title: "产品管理",
    name: "产品名称",
    month: "购买几个月",
    gift_month: "赠送几个月",
    price: "价格",
    discounted_price: "折扣价格",
    zhname: "产品中文名称",
    ugname: "产品维文名称",
    edit: "编辑产品",
    create: "新建产品",
  },
  orders: {
    title: "订单管理",
    num: "订单编号",
    created_at: "订单创建时间",
  },
};
