// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ra: {
    notification: {
      updated: "مۇۋەپپەقىيەتلىك يېڭىلاندى",
      deleted: "مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى",
      item_doesnt_exist: "بۇ مەزمۇن تېپىلمىدى",
      created: "مۇۋەپپەقىيەتلىك قوشۇلدى",
    },
    validation: {
      required: "چوقۇم تولدۇرۇسىز",
    },
    input: {
      references: {
        single_missing: "مۇناسىۋەتلىك مەزمۇن تېپىلمىدى",
      },

      image: {
        upload_single:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
        upload_several:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
      },
    },
    auth: {
      auth_check_error: "كىرىپ مەشغۇلات قىلىڭ",
      sign_in: "كىرىش",
      username: "ئەزا نامى",
      password: "پارول",
      logout: "چېكىنىش",
      user_menu: "تەڭشەك",
    },
    action: {
      remove: "ئۆچۈرۈش",
      unselect: "تاللانمىغان",
      open_menu: "ئېچىش",
      close_menu: "تاقاش",
      undo: "قايتۇرۇش",
      sort: "رەتكە تىزىش",
      undi: "قايتۇرۇش",
      back: "قايتۇرۇش",
      add: "قوشۇش",
      save: "ساقلاش",
      create: "قوشۇش",
      export: "جەدۋەل",
      edit: "تەھرىرلەش",
      refresh: "يېڭىلاش",
      delete: "ئۆچۈۈش",
      bulk_actions: "كۆپ تاللاش",
    },
    message: {
      details: "تېخىۇ كۆپ خاتالىق ئۇچۇرىنى كۆرۈش",
      error: "كۈتۈلمىگەن خاتالىق كۆرۈلدى، قايتا سىناڭ",
    },
    navigation: {
      skip_nav: "",
      prev: "ئالدىدىكىسى",
      next: "كەينىدىكىسى",
      page_rows_per_page: "ھەر بەتتە",
      page_range_info: "",
      no_results: "نەتىجىسى قۇرۇق",
    },
    page: {
      list: "تىزىملىك",
      error: "خاتالىق كۆرۈلدى",
      dashboard: "ئارقا سۇپا",
      invite: "يېڭىدىن قوشامسىز؟",
      empty: "مەزمۇن يوق",
      create: "قوشۇش",
    },
  },
  menu: {
    accounts: "ئاكونت باشقۇرۇش",
    subemployee: "خادىم باشقۇرۇش",
    roles: "ھوقۇق باشقۇرۇش",
    employees: "خادىملار",
    subproduct: "مەھسۇلات باشقۇرۇش",
    categories: "مەھسۇلات تۈرى",
    units: "مەھسۇلات بىرلىكى",
    products: "مەھسۇلاتلار",
    subinventory: "ئامبار باشقۇرۇش",
    inventory: "ئامبار قالدۇقى",
    inventorycount: " ئامبار سانىقى",
    inventorycheck: "ساناق تارىخى",
    subpurchase: "مال كىرگۈزۈش",
    suppliers: "مال تەمىنلىگۈچى",
    purchase: "مال كىرگۈزۈش تالونى",
    subpermission: "ئەزا باشقۇرۇش",
    members: "ئەزالار",
    levels: "ئەزا دەرىجىسى",
    types: "پۇل تۆلەش شەكلى",
  },
  common: {
    switch: "تىل ئالماشتۇرۇش",
    account: "ئاكونت ئالماشتۇرۇش",
    zhname: "خەنزۇچە ئىسمى",
    ugname: "ئۇيغۇرچە ئىسمى",
    choose: "ئاكونت تاللاش",
    chooseaccount: "ئاكونت تاللاش",
    logintitle: "进销存后台管理系统",
  },
  accounts: {
    mobile: "يانفۇن",
    member: "كىرىش يانفۇن",
    password: "مەخپى نومۇر",
    search: "ئىزدەش",
    account: "ئاكونت",
    contact: "ئالاقىلاشقۇچى",
    name: "ئاكونت نامى",
    paytime: "پۇل تۆلىگەن ۋاقتى",
    start: "ئىشلىتىشكە باشلىغان ۋاقتى",
    end: "ئاخىرلىشىش ۋاقتى",
    edit: "ئاكونت تەھرىرلەش",
    create: "ئاكونت قوشۇش",
  },
  official: {
    tryaccount: "سىناق ئاككونت",
    tryaccountdel: "ۋاقتى توشقان سىناق ئ‍اككونت",
    account: "رەسمى ئاككونت",
    accountdel: "ۋاقتى توشقان رەسمى ئاككونت",
    search: "ئىزدەش",
    start_at: "باشلىنىش ۋاقتى",
    end_at: "ئاياغلىشىش ۋاقتى",
    expired_at: "ئاخىرلىشىش ۋاقتى",
    lang: "ug",
  },
  promotion: {
    title: "产品",
    name: "产品名称",
    month: "购买几个月",
    gift_month: "赠送几个月",
    price: "价格",
    discounted_price: "折扣价格",
    zhname: "产品中文名称",
    ugname: "产品维文名称",
    edit: "编辑产品",
    create: "新建产品",
  },
  orders: {
    title: "订单",
    num: "订单编号",
    created_at: "订单创建时间",
  },
};
