import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  img: {
    maxHeight: "4rem",
  },
}));

export const CateList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List {...props} title="帮助目录" className={classes.ugTitle}>
      {isSmall ? (
        <SimpleList
          primaryText={() => "帮助目录名称"}
          secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
        />
      ) : (
        <Datagrid>
          <TextField label="帮助目录（中文）" source="name.zh" />
          <TextField label="帮助目录（维文）" source="name.ug" />

          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export const CateEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title="编辑帮助目录" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput
          source="name.zh"
          label="帮助目录（中文）"
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label="帮助目录（维文）"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export const CateCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} title="新建帮助目录" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source="name.zh"
          label="帮助目录（中文）"
          validate={required()}
        />
        <TextInput
          source="name.ug"
          label="帮助目录（维文）"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
