import { stringify } from "qs";
import axios from "axios";

let baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.tijaretchi.bulutbazar.com/v1/"
    : "/v1/";

let lang = () => {
  const language = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ug";
  return language;
};

const httpClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "Accept-Language": lang(),
  },
});

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const withImageResource = ["member/levels", "product/categories"];

httpClient.interceptors.request.use(
  function (config) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.access_token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${auth.access_token}`,
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    throw new Error(response.data.message);
  }
);

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage,
    };
    if (resource === "admin/accounts" || resource === "admin/accountsDel") {
      if (resource === "admin/accountsDel") {
        resource = "admin/accounts";
        query.expired = "1";
      }
      query.type = "1";
      if (params.filter.start_at) {
        query.start_at = params.filter.start_at;
        delete params.filter.start_at;
      }
      if (params.filter.end_at) {
        query.end_at = params.filter.end_at;
        delete params.filter.end_at;
      }
      if (params.filter.keyword) {
        query.keyword = params.filter.keyword;
        delete params.filter.keyword;
      }
    }

    if (
      resource === "admin/tryaccounts" ||
      resource === "admin/tryaccountsDel"
    ) {
      if (resource === "admin/tryaccountsDel") {
        resource = "admin/accounts";
        query.expired = "1";
      }
      resource = "admin/accounts";
      query.type = "0";
      if (params.filter.start_at) {
        query.start_at = params.filter.start_at;
        delete params.filter.start_at;
      }
      if (params.filter.end_at) {
        query.end_at = params.filter.end_at;
        delete params.filter.end_at;
      }
      if (params.filter.keyword) {
        query.keyword = params.filter.keyword;
        delete params.filter.keyword;
      }
    }

    if (params.filter && Object.keys(params.filter).length > 0) {
      let tmp = Object.keys(params.filter).reduce((sum, k, idx) => {
        sum += `${k}:${params.filter[k]}`;
        if (idx > 0) {
          sum += ";";
        }
        return sum;
      }, "");
      query.search = tmp;
    }

    if (resource === "inventory/checks") {
      query.include = "details";
    }

    if (resource === "helps/chay") {
      resource = "helps";
      query.search = "type:0";
    }
    if (resource === "helps/tijaretchi") {
      resource = "helps";
      query.search = "type:1";
    }
    if (resource === "helps/re") {
      resource = "helps";
      query.search = "type:2";
    }

    const url = `${resource}?${stringify(query)}`;
    return httpClient(url).then(({ data: repData }) => {
      const {
        data,
        meta: { pagination },
      } = repData;

      return {
        data: data ?? [],
        total: pagination ? pagination.total : 10,
      };
    });
  },

  getOne: (resource, params) => {
    if (
      resource === "admin/tryaccounts" ||
      resource === "admin/accounts" ||
      resource === "admin/accountsDel" ||
      resource === "admin/tryaccountsDel"
    ) {
      resource = "accounts";
    }

    if (
      resource === "helps/chay" ||
      resource === "helps/tijaretchi" ||
      resource === "helps/re"
    ) {
      resource = "helps";
    }

    return httpClient(`${resource}/${params.id}`).then(({ data }) => ({
      data: data?.data,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    const url = `${resource}?${stringify(query)}`;
    return httpClient(url).then(({ data }) => ({
      data: data ? data.data : [],
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage,
    };
    const url = `${resource}?${stringify(query)}`;

    return httpClient(url).then(({ data: resData }) => {
      const {
        data,
        meta: { pagination },
      } = resData;
      return {
        data: data?.data,
        total: pagination ? pagination.total : null,
      };
    });
  },

  update: async (resource, params) => {
    if (resource === "account/promotions") {
      console.log(params);

      return httpClient(`${resource}/${params.id}`, {
        method: "PATCH",
        data: stringify(params.data),
      }).then(({ data }) => ({ data: data?.data }));
    }

    if (resource === "admin/tryaccounts" || resource === "admin/accounts") {
      resource = "accounts";
    }

    if (resource === "helps/chay") {
      resource = "helps";
      return httpClient(`${resource}/${params.id}?type=0`, {
        method: "PUT",
        data: stringify(params.data),
      }).then(({ data }) => ({ data: data?.data }));
    }
    if (resource === "helps/tijaretchi") {
      resource = "helps";
      return httpClient(`${resource}/${params.id}?type=1`, {
        method: "PUT",
        data: stringify(params.data),
      }).then(({ data }) => ({ data: data?.data }));
    }
    if (resource === "helps/re") {
      resource = "helps";
      return httpClient(`${resource}/${params.id}?type=2`, {
        method: "PUT",
        data: stringify(params.data),
      }).then(({ data }) => ({ data: data?.data }));
    }

    if (withImageResource.includes(resource)) {
      const { image, ...others } = params.data;
      let tmp = {};
      if (image?.ug?.rawFile instanceof File) {
        tmp.ug_image = await convertFileToBase64(image.ug);
      }
      if (image?.zh?.rawFile instanceof File) {
        tmp.zh_image = await convertFileToBase64(image.zh);
      }
      if (image?.rawFile instanceof File) {
        tmp.image = await convertFileToBase64(image);
      }
      return httpClient(`${resource}/${params.id}`, {
        ...params,
        method: "PUT",
        data: stringify({
          ...others,
          ...tmp,
        }),
      }).then(({ data }) => ({
        data: data?.data,
      }));
    }

    return httpClient(`${resource}/${params.id}`, {
      method: "PUT",
      data: stringify(params.data),
    }).then(({ data }) => ({ data: data?.data }));
  },

  updateMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "PUT",
      data: stringify(params.data),
    }).then(({ data }) => ({ data }));
  },

  create: async (resource, params) => {
    if (resource === "pro") {
      const { images, ...others } = params.data;
      if (images) {
        return Promise.all(images.map(convertFileToBase64)).then(
          (base64Pictures) =>
            httpClient(resource, {
              ...params,
              method: "POST",
              data: stringify({
                ...others,
                images: base64Pictures,
              }),
            }).then(({ data }) => ({
              data: {
                ...others,
                images: base64Pictures,
                id: data.data.id,
              },
            }))
        );
      }
    }
    if (resource === "account/promotions") {
      let description = { zh: "", ug: "" };
      description.zh = params.data.zh;
      description.ug = params.data.ug;
      delete params.data.zh;
      delete params.data.ug;
      params.data.description = description;
    }

    if (resource === "admin/tryaccounts" || resource === "admin/accounts") {
      resource = "accounts";
    }

    if (resource === "helps/chay") {
      resource = "helps?type=0";
    }
    if (resource === "helps/tijaretchi") {
      resource = "helps?type=1";
    }
    if (resource === "helps/re") {
      resource = "helps?type=2";
    }

    if (withImageResource.includes(resource)) {
      const { image, ...others } = params.data;
      if (image) {
        return Promise.all([image.ug, image.zh].map(convertFileToBase64)).then(
          (base64Pictures) =>
            httpClient(resource, {
              ...params,
              method: "POST",
              data: stringify({
                ...others,
                ug_image: base64Pictures[0],
                zh_image: base64Pictures[1],
              }),
            }).then(({ data }) => ({
              data: {
                ...others,
                ug_image: base64Pictures[0],
                zh_image: base64Pictures[1],
                id: data.data.id,
              },
            }))
        );
      }
    }
    return httpClient(resource, {
      method: "POST",
      data: stringify(params.data),
    }).then(({ data }) => ({
      data: { ...params.data, id: data.data.id },
    }));
  },

  delete: (resource, params) => {
    if (
      resource === "helps/chay" ||
      resource === "helps/tijaretchi" ||
      resource === "helps/re"
    ) {
      resource = "helps";
    }

    return httpClient(`${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data }));
  },

  deleteMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data }));
  },
};

export { httpClient };

export default dataProvider;
