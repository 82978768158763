import * as React from "react";
import { Admin, Resource, useLocale, useTranslate } from "react-admin";
import dataProvider from "./http";
import authProvider from "./auth";
import MyLayout from "./layout/Layout";
import Dashboard from "./Dashboard";
import i18nProvider from "./i18nProvider";

import { AccountsList, AccountsEdit, AccountsCreate } from "./accounts";
import {
  TryAccountsCreate,
  TryAccountsEdit,
  TryAccountsList,
} from "./tryaccount";
import { DelAccountsEdit, DelAccountsList } from "./accountsDel";
import { DelTryAccountsEdit, DelTryAccountsList } from "./tryaccountDel";
import "./index.css";
import { PromotionsCreate, PromotionsEdit, PromotionsList } from "./promotions";
import { OrderssList } from "./orders";
import {
  RecommendersCreate,
  RecommendersEdit,
  RecommendersList,
} from "./recommenders";
import { WechatCreate, WechatEdit, WechatList } from "./wechat";
import { HelpCreate, HelpEdit, HelpList } from "./helps";
import { CateCreate, CateEdit, CateList } from "./helpcate";
import { HelpTiCreate, HelpTiEdit, HelpTiList } from "./helpti";
import { HelpReCreate, HelpReEdit, HelpReList } from "./helpre";

const App = () => {
  const translate = useTranslate();
  const locale = localStorage.getItem("lang");
  console.log(locale);

  return (
    <div style={locale === "zh" ? { direction: "ltr" } : { direction: "rtl" }}>
      <Admin
        title=""
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        disableTelemetry
      >
        <Resource
          options={{ label: translate("official.account") }}
          name="admin/accounts"
          list={AccountsList}
          create={AccountsCreate}
          edit={AccountsEdit}
        />
        <Resource
          options={{ label: translate("official.accountdel") }}
          name="admin/accountsDel"
          list={DelAccountsList}
          edit={DelAccountsEdit}
        />
        <Resource
          options={{ label: translate("official.tryaccount") }}
          name="admin/tryaccounts"
          list={TryAccountsList}
          create={TryAccountsCreate}
          edit={TryAccountsEdit}
        />
        <Resource
          options={{ label: translate("official.tryaccountdel") }}
          name="admin/tryaccountsDel"
          list={DelTryAccountsList}
          edit={DelTryAccountsEdit}
        />
        <Resource
          options={{ label: translate("promotion.title") }}
          name="account/promotions"
          list={PromotionsList}
          create={PromotionsCreate}
          edit={PromotionsEdit}
        />
        <Resource
          options={{ label: "订单" }}
          name="account/orders"
          list={OrderssList}
        />

        <Resource
          options={{ label: "推荐" }}
          name="recommenders"
          list={RecommendersList}
          create={RecommendersCreate}
          edit={RecommendersEdit}
        />
        <Resource
          options={{ label: "支付" }}
          name="wechat/merchant/payments"
          list={WechatList}
          create={WechatCreate}
          edit={WechatEdit}
        />
        <Resource
          options={{ label: "帮助目录" }}
          name="help/categories"
          list={CateList}
          create={CateCreate}
          edit={CateEdit}
        />
        <Resource
          options={{ label: "茶会帮助" }}
          name="helps/chay"
          list={HelpList}
          create={HelpCreate}
          edit={HelpEdit}
        />
        <Resource
          options={{ label: "Tigaretchi帮助" }}
          name="helps/tijaretchi"
          list={HelpTiList}
          create={HelpTiCreate}
          edit={HelpTiEdit}
        />
        <Resource
          options={{ label: "销售宝帮助" }}
          name="helps/re"
          list={HelpReList}
          create={HelpReCreate}
          edit={HelpReEdit}
        />
        <Resource name="users" />
      </Admin>
    </div>
  );
};
export default App;
