import * as React from "react";
import { forwardRef, memo } from "react";
import {
  Layout,
  AppBar,
  UserMenu,
  useLocale,
  useSetLocale,
  useTranslate,
} from "react-admin";
import { MenuItem, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Language from "@material-ui/icons/Language";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  icon: { minWidth: theme.spacing(5) },
}));

const SwitchLanguage = forwardRef((props, ref) => {
  let locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const translate = useTranslate();
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <MenuItem
      ref={ref}
      className={classes.menuItem}
      onClick={() => {
        locale = locale === "ug" ? "zh" : "ug";
        setLocale(locale);
        localStorage.setItem("lang", locale);
        history.go(pathname);
      }}
    >
      <ListItemIcon className={classes.icon}>
        <Language />
      </ListItemIcon>
      {translate("common.switch")}
    </MenuItem>
  );
});

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <SwitchLanguage />
  </UserMenu>
);

const MyAppBar = memo((props) => (
  <AppBar {...props} userMenu={<MyUserMenu />} />
));

const MyLayout = (props) => {
  return <Layout {...props} appBar={MyAppBar} menu={Menu} />;
};

export default MyLayout;
